<template>
  <v-tooltip :disabled="multiline != undefined || !field.tooltip || field.tooltipIcon" top v-if="field.type === 'select' && (field.show == undefined || field.show)">
    <template v-slot:activator="{ on, attrs }">
      <component :is="tag"
        v-bind="attrs" v-on="on"
        v-if="field.type === 'select' && (field.show == undefined || field.show)"
        :key="field.name"
        :name="field.name"
        :ref="field.name"
        :label="(multiline == undefined && !field.hideLabel) ? field.label : ''"
        v-model="vmodel[field.name]"
        :items="field.options"
        item-text="option.text"
        item-value="option.value"
        outlined
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        dense
        hide-details
        :multiple="field.multiple"
        :no-data-text="`Nenhum(a) ${field.label || 'dado'} encontrado(a)`"
        :disabled="field.disabled || disabled"
        :readonly="field.readonly || blockField(field.name, multiline) || blockLine()"
        :rules="FormLines.rules(field)"
        :class="{ 'disabled': field.disabled || field.readonly || blockField(field.name, multiline) || blockLine(), [field.class]: field.class }"
        :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`"
        @click="event(field.click)"
        @change="event(field.change)"
        :background-color="backgroundColor()"
        :loading="field.loading"
        :color="FormLines.styles['--over-color']"
        :autofocus="field.focus"
      >
        <template v-if="field.multiple && vmodel[field.name]" v-slot:selection="{ item, index }">
          <v-chip v-if="!field.showFirstSelected && item && index === 0" small>
            <span>{{ vmodel[field.name].length }}</span>
          </v-chip>
          <v-chip v-if="field.showFirstSelected && item && index === 0" small>
            <span>{{ field.options.find(option => option.option.value == vmodel[field.name][0]).option.text }}</span>
          </v-chip>
          <v-chip v-if="field.showFirstSelected && item && index === 1" small>
            <span>+{{ vmodel[field.name].length - 1 }}</span>
          </v-chip>
        </template>
        <template slot="append">
          <v-icon v-for="(icon, index) in FormLines.showIcon(field)" :key="index" :class="{ 'redIcon': icon.error }">{{ icon.name }}</v-icon>
          <AppendIconTooltip :field="field" />
          <AppendIconLink :value="vmodel[field.name]" />
          <template v-for="action in field.actions">
            <v-icon v-if="action.type == 'icon'" :key="action.text" @click="event(action.method)" class="hover">{{ action.text }}</v-icon>
          </template>
          <v-icon>mdi-menu-down</v-icon>
        </template>
        <template slot="append-outer">
          <template v-for="action in field.actions">
            <v-btn
              v-if="action.type == 'button'"
              :key="action.text"
              @click="event(action.method)"
              outlined
              class="inLineButton"
              :loading="action.loading"
              :color="FormLines.styles['--over-color']"
            >{{ action.text }} </v-btn>
          </template>
        </template>
        <template v-slot:prepend-item v-if="field.multiple && vmodel[field.name]">
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action><v-icon :color="vmodel[field.name].length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon></v-list-item-action>
            <v-list-item-content><v-list-item-title>Selecionar Tudo</v-list-item-title></v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </component>
    </template>
    <span v-if="!field.tooltipIcon">{{ field.tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { VSelect, VAutocomplete } from 'vuetify/lib'
import * as js_functions from '@/lib/js_functions'
import { mapState } from 'vuex'
import AppendIconLink from './pieces/AppendIconLink'
import AppendIconTooltip from './pieces/AppendIconTooltip'
export default {
  name: "FieldSelect",
  props: ['FormLines', 'logic', 'field', 'vmodel', 'multiline', 'line', 'disabled'],
  components: { VSelect, VAutocomplete, AppendIconLink, AppendIconTooltip },
  computed: {
    ...mapState(['register']),
    selectedAll() {
      return this.vmodel[this.field.name]?.length === this.field.options.length
    },
    selectedSome() {
      return this.vmodel[this.field.name]?.length > 0 && !this.selectedAll
    },
    icon () {
      if (this.selectedAll) return 'mdi-close-box'
      if (this.selectedSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    tag() { return (this.field.autocomplete || this.field.autocomplete == undefined) ? 'VAutocomplete' : 'VSelect' }
  },
  watch: {
    'field.options': {
      deep: true,
      handler() { if (this.field.addlink) { js_functions.addLinkDescription(this.vmodel, this.field.name, this.field.options) } }
    }
  },
  methods: {
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    blockField(fieldName, multilinesName) { return this.$store.getters.blockField({ fieldName, multilinesName }) },
    blockLine() {
      const enabledLine = 'enabledLine' in this.vmodel
      return this.field.disabledLine && !enabledLine ? true : false
    },
    getLinkDescription() { return js_functions.getOptionTextByValue(this.field.options, this.vmodel[this.field.name]) },
    event(functionName) { if (functionName) { this.logic[functionName]({ parent: this._parent(), FormLines: this.FormLines, name: this.field.name, value: this.vmodel[this.field.name], text: this.getLinkDescription(), vmodel: this.vmodel, lineNumber: this.line }) } return false },
    backgroundColor() {
      if (this.field.backgroundcolor) { return this.field.backgroundcolor }
      const value = this.vmodel ? this.vmodel[this.field.name] : this.register[this.field.name]
      return js_functions.getOptionValueByOtherPropValue(this.field.options, 'value', value, 'color')
    },
    defaultValue() { this.vmodel[this.field.name] = (!this.field.defaultvalue || this.vmodel[this.field.name]) ? this.vmodel[this.field.name] : this.field.defaultvalue },
    async toggle() {
      await this.$nextTick(() => {
        if (this.selectedAll) { this.vmodel[this.field.name] = [] }
        else { this.vmodel[this.field.name] = this.field.options.map(option => option.option.value) }
      })
      if (this.field.change) { this.event(this.field.change) }
    }
  },
  created() { this.defaultValue() },
  mounted() {
    if (this.field.addlink) {
      js_functions.addLinkDescription(this.vmodel, this.field.name, this.field.options)
      this.$watch(`vmodel.${this.field.name}`, () => { js_functions.addLinkDescription(this.vmodel, this.field.name, this.field.options) })
    }
    this.multiline == undefined || this.$parent.lineFocus(this)
  }
}
</script>

<style scoped>
  .v-btn.inLineButton { margin-top: -6px; }
</style>