<template>
  <div class="line" :class="{[lines.class]: lines.class, 'actions': lines.actions, 'notMultilines': !lines.fields || !lines.fields[0] || lines.fields[0].type != 'multilines', 'functionsLine': lines.number == 0, 'notTable': !lines.fields || !lines.fields[0] || lines.fields[0].type != 'table' }">
    <template v-for="action in lines.actions">
      <v-btn v-if="action.type == 'button' && (action.show == true || action.show == undefined)" :key="action.text" @click="event(action.method)" outlined class="actionButton" :class="{ 'active': actionIsActive(action.method) }" :loading="action.loading" :color="styles['--over-color']">{{ action.text }}</v-btn>
    </template>
    <div v-if="lines.subtitle && lines.number != '0' && (lines.show == undefined || lines.show)" :class="(lines.subtitleStyle || lines.subtitleStyle == undefined) ? 'subtitle' : 'subtitleNotFancy'">
      {{ lines.subtitle }}
        <template v-for="action in lines.subtitleActions">
          <v-icon v-if="action.type == 'icon' && !action.outer" :key="action.text" @click="event(action.method)" class="hover">{{action.text}}</v-icon>
          <v-btn
            v-if="action.type == 'button' && !action.outer && (action.show == true || action.show == undefined)"
            :style="action.enabled ? 'pointer-events: auto;' : ''"
            :key="action.text"
            @click="event(action.method)"
            outlined
            small
            :disabled="action.disabled"
            class="inLineButton"
            :loading="action.loading"
            :color="styles['--over-color']"
          >{{ action.text }}</v-btn>
        </template>
    </div>
    <template v-for="field in lines.fields">
      <FieldText v-if="field.type === 'text' || field.type === 'password'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldSelect v-if="field.type === 'select'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldTextarea v-if="field.type === 'textarea'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldLink v-if="field.type === 'link'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldLinkSimple v-if="field.type === 'linkSimple'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldMultilines v-if="field.type === 'multilines'" :key="field.name" :field="field" :vmodel="vmodel" :logic="logic" :FormLines="FormLines"/>
      <FieldMap v-if="field.type === 'map'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldTimeline v-if="field.type === 'timeline'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldTimelineControler v-if="field.type === 'timelineControler'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldChart v-if="field.type === 'chart'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldColorPicker v-if="field.type === 'colorPicker'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldDatePicker v-if="field.type === 'datePicker'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldHTML v-if="field.type === 'html'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldStorage v-if="field.type === 'storage'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldStorageList v-if="field.type === 'storageList'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldImage v-if="field.type === 'image'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <DataTable v-if="field.type === 'table' && logic != undefined" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
      <FieldButton v-if="field.type === 'button'" :key="field.name" :field="field" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FieldText from './FieldText'
import FieldSelect from './FieldSelect'
import FieldTextarea from './FieldTextarea'
import FieldLink from './FieldLink'
import FieldLinkSimple from './FieldLinkSimple'
import FieldMultilines from './FieldMultilines'
import FieldMap from './FieldMap'
import FieldTimeline from './FieldTimeline'
import FieldTimelineControler from './FieldTimelineControler'
import FieldChart from './FieldChart'
import FieldColorPicker from './FieldColorPicker'
import FieldDatePicker from './FieldDatePicker'
import FieldHTML from './FieldHTML'
import FieldStorage from './FieldStorage'
import FieldStorageList from './FieldStorageList'
import FieldImage from './FieldImage'
import DataTable from './DataTable'
import FieldButton from './FieldButton'
export default {
  name: "LineFields",
  components: { FieldText, FieldSelect, FieldTextarea, FieldLink, FieldLinkSimple, FieldMultilines, FieldMap, FieldTimeline, FieldTimelineControler, FieldChart, FieldColorPicker, FieldDatePicker, FieldHTML, FieldStorage, FieldStorageList, DataTable, FieldImage, FieldButton },
  computed: { ...mapState(['styles']) },
  props: ['FormLines', 'lines', 'logic', 'vmodel', 'register'],
  data: () => ({
    components: { FieldMap: [], FieldChart: [] },
  }),
  methods: {
    actionIsActive(method) { return this.FormLines.actionsActive.includes(method) },
    event(functionName) {
      this.actionIsActive(functionName) ? this.FormLines.actionsActive = this.FormLines.actionsActive.filter(action => action !== functionName) : this.FormLines.actionsActive.push(functionName)
      if (functionName && this.logic && this.logic[functionName]) { this.logic[functionName]({ parent: this.FormLines, FormLines: this.FormLines }) }
    }
  }
}
</script>

<style lang="scss">
</style>