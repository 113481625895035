"use strict"

export class Form {
  #register
  #fields = []
  constructor(data) {
    Object.assign(this, data)
    this.#register = data.register
  }
  get register() { return this.#register }
}