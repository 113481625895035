<template>
  <v-card id="divLogin" class="mx-auto my-auto pa-10" @keydown.enter="login">
    <v-card-title><img id="imgLogo" v-if="subdomain" :src="`${url}/${user.company}/logo.png`" :alt="user.company"/></v-card-title>
    <v-form ref="form">
      <v-text-field v-if="!subdomain" v-model="user.company" label="Empresa" name="company"></v-text-field>
      <v-text-field v-model="user.username" label="Usuário" name="username" autofocus></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        name="password"
        label="Senha"
        counter
        @click:append="show = !show"
      ></v-text-field>
      <div class="d-flex mt-5 mb-5">
        <v-btn @click="login">Entrar</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import * as backend from '@/lib/backend'
import { baseFilesUrl, userKey } from '@/global'
import * as cache from '@/lib/cache'
export default {
  name: "Login",
  data: () => ({
    subdomain: '',
    url: baseFilesUrl,
    user: { company: "", username: "", name: "" },
    show: false,
    password: ''
  }),
  methods: {
    async login() {
      await backend.post({
        company: 'signin/' + this.user.company,
        module: 'controls',
        dataId: '_users',
        body: { ...this.user, password: this.password },
        early: true
      }).then(res => {
        this.$store.commit("showAlert", { type: 'success', text: 'Login válido!' })
        setTimeout(async () => {
          await cache.getCacheVersions(this.user.company)
          this.$store.commit("setPermission", res.data.permission)
          this.$store.commit('login', { ...res.data })
          localStorage.setItem(userKey, JSON.stringify(res.data))
          this.$emit('showApp', true)
          this.$store.commit("hideAlert")
          this.password = ''
        }, 1000)
      })
      .catch(() => { this.$store.commit("showAlert", { type: 'error', text: 'Login Inválido' }) })
    },
    getSubdomainCompany() {
      const _host = window.location.hostname
      this.subdomain = this.user.company = (_host.includes('.localhost') || _host.includes('.btb.app.br')) ? _host.split('.')[0] : ''
    }
  },
  created() { this.getSubdomainCompany() }
}
</script>

<style lang="scss">
  div#divLogin div.v-card__title { justify-content: space-around; }
  img#imgLogo { max-width: 200px; }
  /* Company Style */
  div#divLogin .theme--light.v-text-field > .v-input__control > .v-input__slot:before { border-color: var(--header-color); }
  div#divLogin .theme--light.v-text-field > .v-input__control > .v-input__slot:after { border-color: var(--header-color); }
  div#divLogin .v-btn { border: 1px solid var(--header-color); flex-grow: 1; }
  div#divLogin .v-btn:hover { background-color: var(--active-background-color); }
  div#divLogin .v-btn:hover * { background: none; }
  #divLogin, #divLogin form * {
    background-color: var(--header-background-color); 
    color: var(--header-color);
  }
  .v-application div#divLogin > form > div.v-input.v-input--is-focused.theme--light.v-text-field { caret-color: var(--login-over-color) !important; } 
  .v-application div#divLogin > form > div.v-input.v-input--is-focused.theme--light.v-text-field .v-label--active { color: var(--login-over-color) !important; } 
  .v-application div#divLogin > form > div.v-input.v-input--is-focused.theme--light.v-text-field .v-icon { color: var(--login-over-color) !important; } 
</style>