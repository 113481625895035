"use strict"

export class Page {
  #Form
  constructor({ Form }) {
    this.#Form = Form
  }
  get Form() { return this.#Form }
  get $store() { return this.#Form.$store }
  #openAlert = ({ type, text, dismissible, time }) => this.$store.commit("showAlert", { type, text, dismissible, time })
  showSuccess = ({ text, dismissible, time }) => this.#openAlert({ type: 'success', text, dismissible, time })
  showInfo = ({ text, dismissible, time }) => this.#openAlert({ type: 'info', text, dismissible, time })
  showWarning = ({ text, dismissible, time }) => this.#openAlert({ type: 'warning', text, dismissible, time })
  showError = ({ text, dismissible, time }) => this.#openAlert({ type: 'error', text, dismissible, time })
  openDialog = ({ dialog }) => this.$store.commit('showDialog', { ...dialog, show: true })
  closeDialog = () => this.$store.commit("hideDialog")
}