String.prototype.upperFirst = function() { return this.charAt(0).toUpperCase() + this.slice(1) }
String.prototype.insert_at = function(index, string) { return this.substr(0, index) + string + this.substr(index) }
String.prototype.splitAt = function(index) { return [this.slice(0, index), this.slice(index)] }
String.prototype.substringBetween = function(start, end, includeStart, includeEnd) {
  if (this.indexOf(start) == -1) return ''
  const startAddIndex = includeStart ? 0 : start.length
  const startIndex = this.indexOf(start) + startAddIndex
  const endAddIndex = includeEnd ? end.length : 0
  const endIndex = (this.lastIndexOf(end) != -1 ? this.lastIndexOf(end) : this.length) + endAddIndex
  return startIndex <= endIndex ? this.substring(startIndex, endIndex) : ''
}
Array.prototype.pushAndReturnArray = function (value) {
  this.push(value)
  return this
}
Array.prototype.unshiftAndReturnArray = function (value) {
  this.unshift(value)
  return this
}
Array.prototype.removeByValue = function (value) {
  const index = this.indexOf(value)
  this.splice(index, 1)
  return this
}
Array.prototype.getLast = function () { return this[this.length - 1] }
Array.prototype.removeLast = function () {
  this.pop()
  return this
}
Array.prototype.getFirst = function () { return this[0] }
Array.prototype.removeFirst = function () {
  this.shift()
  return this
}
Array.prototype.removeDuplicates = function () { return [...new Set(this)] }
Array.prototype.removeDuplicatesObjects = function() { return this.filter((value, index) => {
  const _value = JSON.stringify(value)
  return index === this.findIndex(obj => { return JSON.stringify(obj) === _value })
})}
Array.prototype.removeDuplicatesObjectsProp = function(prop) { return this.filter((obj, index) => {
  const _value = obj[prop]
  return index === this.findIndex(_obj => { return _obj[prop] === _value })
})}
Array.prototype.joinLastDifferent = function(separator, lastSeparator) { return this.slice(0, -1).join(separator) + (this.length > 2 ? lastSeparator : '') + this.slice(-1) }
export const isNumber = function(value) { return typeof value === 'number' && isFinite(value) }
// export const shuffle = function(array) {
Array.prototype.shuffle = function () {
  let currentIndex = this.length
  let randomIndex
  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    // And swap it with the current element.
    [this[currentIndex], this[randomIndex]] = [this[randomIndex], this[currentIndex]]
  }
  return this
}
Array.prototype.clear = function () {
  this.splice(0, this.length)
  return this
}
export const updateValue = function(params) {
  const { value, baseIndex, currentIndex } = params
  const updatedValue = numberIn(value) * (numberIn(currentIndex) / numberIn(baseIndex))
  return numberOut(updatedValue, 2)
}
export const isEqual = (params) => {
  let { firtsThing, secondThing } = params
  const { sameAsUndefined, emptyArrayIsUndefined } = params
  const convertUndefined = (value) => (sameAsUndefined && sameAsUndefined.includes(value)) || (emptyArrayIsUndefined && Array.isArray(value) && value.length == 0) ? undefined : value
  firtsThing = convertUndefined(firtsThing)
  secondThing = convertUndefined(secondThing)
  if (Array.isArray(firtsThing) || Array.isArray(secondThing)) { return (Array.isArray(firtsThing) && Array.isArray(secondThing)) ? JSON.stringify(firtsThing) == JSON.stringify(secondThing) : false }
  return firtsThing == secondThing
}
export const removeEmptyProperties = (object) => {
  if (Array.isArray(object)) { return object }
  let _object = {}
  for (const property in object) {
    if (object[property] != '') { _object[property] = object[property] }
  }
  return _object
}
export const cleamObjectsArray = (array) => array.map(obj => removeEmptyProperties(obj))
export const countValuesInArray = (array, prop, value) => array.filter(item => item[prop] == value).length
export const executeIfUniq = params => {
  params.array?.reduce((arrayUniq, item) => {
    const value = params.prop ? item[params.prop] : item
    if (!arrayUniq.includes(value)) {
      params.func(item)
      arrayUniq.push(value)
    }
    return arrayUniq
  }, [])
}
export function findDuplicates(arr) { return arr.filter((item, index) => arr.indexOf(item) != index) }
export const removeDuplicates = (array) => [...new Set(array)]
export const removeDuplicatesObjects = (arr) => arr.filter((value, index) => {
  const _value = JSON.stringify(value)
  return index === arr.findIndex(obj => { return JSON.stringify(obj) === _value })
})
export const removeDuplicatesObjectsProp = (arr, prop) => arr.filter((obj, index) => {
  const _value = obj[prop]
  return index === arr.findIndex(_obj => { return _obj[prop] === _value })
})
export function getOptionTextByValue(options, value) {
  const selectedOption = options.find(item => item.option.value == value)
  if (selectedOption) { return selectedOption.option.text }
}
export function getOptionValueByOtherPropValue(options, findPropName, findPropValue, valuePropName) {
  const selectedOption = options?.find(item => item.option[findPropName] == findPropValue)
  if (selectedOption) { return selectedOption.option[valuePropName] }
}

export function addLinkDescription(vmodel, fieldName, options) {
  if (vmodel[fieldName]) {
    const text = getOptionTextByValue(options, vmodel[fieldName])
    if (vmodel[`link_${fieldName}`] != text) { vmodel[`link_${fieldName}`] = text }
  }
  else { vmodel[`link_${fieldName}`] = '' }
}
export function getColorForPercentage(pct) {
  // const percentColors2 = [{ pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } }, { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } }, { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }];
  const percentColors = [{ pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } }, { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } }, { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } }];
  for (let i = 0; i < percentColors.length; i++) {
    if (pct <= percentColors[i].pct) {
      let j = (i != 0) ? i - 1 : 0
      let lower = percentColors[j];
      let upper = percentColors[i];
      let range = upper.pct - lower.pct;
      let rangePct = (pct - lower.pct) / range;
      let pctLower = 1 - rangePct;
      let pctUpper = rangePct;
      let color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
      };
      return 'rgb(' + [color.r, color.g, color.b].join(',') + ')'; // or output as hex if preferred
    }
  }
}
export function getArrayValue(array, position) {
  if (!array || array.length < 1) { return '' }
  switch (position) {
    case 'first':
      return array[0]
    case 'last':
      return array[array.length-1]
    default:
      return array[0]
  }
}
export function getObjectValue(object, key) { return (!object || !object[key]) ? '' : object[key] }
export function getConnectionIdByDataId(connections, dataId, position, prop) {
  if (!connections) { return false }
  const results = connections.filter(connection => connection.dataId == dataId)
  if (results.length == 0) { return false }
  if (!position) { position = 'first' }
  if (!prop) { prop = '_id' }
  switch (position) {
    case 'first':
      return results[0][prop]
    case 'last':
      return results[results.length-1][prop]
    case 'all':
      return results.map(connection => connection[prop])
    default:
      return results[position][prop]
  }
}
export const operationArrayProp = (array, operation, prop, patern) => Array.isArray(array) ? operationArray(array.map(item => item ? item[prop] : null), operation, patern) : false
export const operation2ArraysProp = (array1, array2, operation, prop1, prop2, patern) => (Array.isArray(array1) && Array.isArray(array2)) ? operation2Arrays(array1.map(item => item[prop1]), array2.map(item => item[prop2]), operation, patern) : false
export function operationArray(array, operation, patern) {
  if (!Array.isArray(array) || array.length == 0) { return false }
  array = array.map(item => numberIn(item, patern))
  switch (operation) {
    case 'average':
      return array.reduce((a, b) => a + b, 0) / array.length
    case 'max':
      return Math.max.apply(Math, array)
    case 'min':
      return Math.min.apply(Math, array)
    default:
      return array.reduce((a, b) => a + b, 0) // Return sum
  }
}
export function operation2Arrays(array1, array2, operation, patern) {
  if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length == 0 || array2.length == 0) { return false }
  array1 = array1.map(item => numberIn(item, patern))
  array2 = array2.map(item => numberIn(item, patern))
  const multiply = () => operationArray(array1.map((item, index) => item * array2[index]), 'sum')
  switch (operation) {
    case 'muliply':
      return multiply()
    case 'weighted':
      return multiply() / operationArray(array2, 'sum')
    default:
      return array1.map((item, index) => item + array2[index]) // Return array of sums
  }
}
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}
export function createObjectDate(date) {
  if (!date || typeof date !== 'string' || date.indexOf('/') < 0) { return false }
  const ddmmaaaa = date.split('/')
  return new Date(ddmmaaaa[2], ddmmaaaa[1] - 1, ddmmaaaa[0])
}
export function getToday() { return dateToString(new Date()) }
export function getNow() { return dateToString(new Date(), { onlyTime: true }) }
export function dateToString(date, options) {
  const day = doubleDigitZero(date.getDate())
  const month = doubleDigitZero(date.getMonth() + 1)
  const year = date.getFullYear()
  let result = (options && options.onlyTime == true) ? '' : `${day}/${month}/${year}`
  if (options && (options.addTime || options.onlyTime)) {
    const hours = doubleDigitZero(date.getHours())
    const minutes = doubleDigitZero(date.getMinutes())
    const seconds = doubleDigitZero(date.getSeconds())
    result = result == '' ? result : `${result} `
    result += `${hours}:${minutes}:${seconds}`
  }
  return result
}
export function doubleDigitZero(number) { return number > 9 ? number : `0${number}` }
export function sumDays(date, days, returnString) {
  const _date = (Object.prototype.toString.call(date) === '[object Date]') ? date : createObjectDate(date)
  const ms = _date.getTime() + (86400000 * days)
  const dateMoreDays = new Date(ms);
  return returnString ? dateToString(dateMoreDays) : dateMoreDays
}
export function diferencaMeses(data1, data2) {
  const blnNotAbsolute = arguments[2] || false
  const dif = (data1.getYear() - data2.getYear())*12 + (data1.getMonth() - data2.getMonth()) + 1
  if (blnNotAbsolute) { return Math.abs(dif) }
  else { return dif }
}
export function diferencaDias(data1, data2) {
  const absolute = arguments[2] || false
  const diffTime = absolute ? Math.abs(data2 - data1) : data2 - data1
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}
export function isDatesTimeSorted(date1, date2) {
  const _date1 = isValidDate(date1) ? date1 : createObjectDate(date1)
  const _date2 = isValidDate(date2) ? date2 : createObjectDate(date2)
  return diferencaDias(_date1, _date2) >= 0
}
export function monthsArray(firstMonth, lastMonth) {
  const _firstMonth = (Object.prototype.toString.call(firstMonth) === '[object Date]') ? firstMonth : createObjectDate(firstMonth)
  const _lastMonth = (Object.prototype.toString.call(lastMonth) === '[object Date]') ? lastMonth : createObjectDate(lastMonth)
  const qtdMeses = diferencaMeses(_lastMonth, _firstMonth)
  return [...Array(qtdMeses).keys()].map(month => new Date(_firstMonth.getFullYear(), _firstMonth.getMonth() + month, _firstMonth.getDate()))
}
// Transform number recived as string to a real number
export function numberIn(number, patern) {
  if (number == undefined) { return 0 }
  let _number = 0
  if (number.indexOf && (number.indexOf(',') > -1 || number.indexOf('.') > -1)) {
    if (number.indexOf('.') > number.indexOf(',')) {
      _number = Number(number.replaceAll(',', ''))
    }
    if (number.indexOf(',') > number.indexOf('.') || patern == 'br') {
      _number = Number(number.replaceAll('.', '').replaceAll(',', '.'))
    }
  }
  else { _number = Number(number) }
  return _number ? _number : 0
}
// Transform number in to a string
export function numberOut(number, decimals, zeroOutput) {
  if ((isNaN(number) || number == false) && zeroOutput) { return zeroOutput }
  if (isNaN(number) || number == false) { number = 0 }
  return `${new Intl.NumberFormat('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(parseFloat(number))}`
}
// Search and return a specific object in a JSON
export function getJSONObject(params) {
  const { fields, name, multilines, groupName } = params
  const _groupName = groupName || 'fields'
  let _fields = []
  fields.map(line => !line.fields || line.fields.map(field => _fields.push(field)))
  fields.map(line => !line.lines || line.lines.map(_line => {
    !_line.fields || _line.fields.map(field => _fields.push(field))
    !_line.actions || _line.actions.map(field => _fields.push(field))
  }))
  fields.map(line => !line.actions || line.actions.map(field => _fields.push(field)))
  const group = multilines ? _fields.find(field => field.type == 'multilines' && field.name == multilines)[_groupName] : _fields.filter(field => field.type != 'multilines' || field.name == name)
  for (let i = 0; i < group.length; i++) {
    if ('name' in group[i] && group[i].name == name) { return group[i] }
    if (group[i].type == 'link' && 'fields' in group[i]) {
      for (let j = 0; j < group[i].fields.length; j++) {
        if ('name' in group[i].fields[j] && group[i].fields[j].name == name) { return group[i].fields[j] }
      }
    }
  }
  return {}
}
export function disableJSONObject(jsonObject, disabled) {
  const label = jsonObject.label
  jsonObject.label = ""
  jsonObject.disabled = disabled
  jsonObject.label = label
}
// Return difference between 2 numbers
export const diffNumber = (a, b) => numberIn(a) - numberIn(b)

export function getUserInfo() {
  let xmlhttp = new XMLHttpRequest()
  var strInfo = ""
  // xmlhttp.open("GET","http://www.geoplugin.net/json.gp",false)
  // xmlhttp.send()
  // strInfo = xmlhttp.responseText
  xmlhttp.open("GET","https://www.cloudflare.com/cdn-cgi/trace",false)
  xmlhttp.send()
  strInfo += xmlhttp.responseText
  return strInfo
}
export function IsEqualValueRange(value1, value2, range) {
  const diff = diffNumber(value1, value2)
  return (diff > range || diff < -range) ? false : true
}
export function timestrToSec(timestr) {
  const parts = timestr.split(":")
  return (parts[0] * 3600) + (parts[1] * 60) + (+parts[2])
}
export const pad = (num) => (num < 10) ? "0" + num : "" + num
export const formatTime = (seconds) => [pad(Math.floor(seconds/3600)), pad(Math.floor(seconds/60)%60), pad(seconds%60), ].join(":")
export const sumTime = (time1, time2) => formatTime(timestrToSec(time1) + timestrToSec(time2))
export function diffTime(time1, time2) {
  const _time1 = time1.split(":")
  const _time2 = time2.split(":")
  const date1 = new Date(2000, 0, 1, _time1[0], _time1[1], _time1[2])
  const date2 = new Date(2000, 0, 1, _time2[0], _time2[1], _time2[2])
  const diff = (date1 - date2) / 1000
  let msec = diff * 1000
  const hh = Math.floor(msec / 1000 / 60 / 60)
  msec -= hh * 1000 * 60 * 60
  const mm = Math.floor(msec / 1000 / 60)
  msec -= mm * 1000 * 60
  const ss = Math.floor(msec / 1000)
  return { sec: diff, time: `${hh > 9 ? '' : '0'}${hh}:${mm > 9 ? '' : '0'}${mm}:${ss > 9 ? '' : '0'}${ss}` }
}

export async function getFunc({ receiver, func, returnData, showAlert }) {
  showAlert = showAlert || true
  const { status, data, alertType, alertText, error } = await func()
  if (showAlert && alertType && alertText) { window.$showAlert({ type: alertType, text: alertText, dismissible: true }) }
  if (data && receiver) { receiver.object[receiver.name] = data }
  if (error) { console.log({error}) }
  return returnData ? { data, status } : status
}
export function calculeFim(FormLines, line) {
  const start = FormLines.Form.js_functions.createObjectDate(line.inicio)
  const dias = line.dias
  if (!FormLines.Form.js_functions.isValidDate(start) || !dias) { return }
  line.fim = FormLines.Form.js_functions.sumDays(start, dias, true)
}
export function calculeAllTasksDays(FormLines) {
  const multilinesNames = []
  FormLines.fields.map(field => {
    !Array.isArray(field.fields) || field.fields.map(_field => {
      if (_field.type == "multilines") { multilinesNames.push(_field.name) }
    })
  })
  multilinesNames.map(multilineName => {
    if (Array.isArray(FormLines.register[multilineName]) && FormLines.register[multilineName].length > 0) {
      FormLines.register[multilineName].map(line => { calculeLineDays({ linha: line }) })
    }
  })
}
export function calculeLineDays({ linha }) {
  const start = createObjectDate(linha.inicio)
  const end = createObjectDate(linha.fim)
  if (!isValidDate(start) || !isValidDate(end)) {
    linha.dias = ''
    return
  }
  const diasNumber = diferencaDias(start, end)
  linha.dias = numberOut(diasNumber)
}
// Função recebe como parâmetro "dd/mm/aaaa" e válida
export function isValidStringDate(dataString) {
  const pattern = /^(\d{2})\/(\d{2})\/(\d{4})$/
  if (!pattern.test(dataString)) { return false }
  const [, dia, mes, ano] = pattern.exec(dataString)
  const data = new Date(`${ano}-${mes}-${dia}`)
  if ( data.getFullYear() == ano && data.getMonth() + 1 == mes && data.getDate() + 1 == dia ) { return true }
  else { return false }
}
export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return '0 Bytes'
  const k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
// Copy from a input field to clipboard
export function copyInputField({ querySelector, alertText }) {
  const element = document.querySelector(querySelector)
  element.select()
  element.setSelectionRange(0, 99999)
  document.execCommand('copy')
  alert(alertText || 'Valores copiados para a área de transferência!')
}
// Copy from a non-input field to clipboard
export function copyNonInputField({ querySelector, fieldName, alertText }) {
  // get the container
  const element = document.querySelector(querySelector)
  // Create a fake `textarea` and set the contents to the text
  // you want to copy
  const storage = document.createElement('textarea')
  storage.value = `<${fieldName}>` + element.innerHTML + `</${fieldName}>`
  element.appendChild(storage)
  // Copy the text in the fake `textarea` and remove the `textarea`
  storage.select()
  storage.setSelectionRange(0, 99999)
  document.execCommand('copy')
  element.removeChild(storage)
  alert(alertText || 'Valores copiados para a área de transferência!')
}
// Copy from a table field to clipboard
export function copyTable({ querySelector }) {
  copyNonInputField({ querySelector, fieldName: 'table', alertText: 'Tabela copiada para a área de transferência!' })
}
export const getObjectWithSelectedProps = (object, props) => Object.assign({}, ...props.map(prop => { return { [prop]: object[prop] } }))
