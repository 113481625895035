import * as universal from '@/lib/core/universal'
import * as js_functions from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
export class iptus {
  #LDVM
  constructor() {
    this.#LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'iptu' })
  }
  getCurrentDataId = ({ register }) => this.#LDVM?.getCurrentDataId({ register })
  setLinkDataVersion = ({ register }) => this.#LDVM.setLinkDataVersion({ register, versionDataId: this.getCurrentDataId({ register }) })
  getIPTU = async function({ action, project, info, register }) {
    const getIPTUQuery = (info) => {
      if (info.sqlc) return { sqlc: info.sqlc }
      let chave = 'sq'
      let valor = `${info.setor}${info.quadra}`
      if (info.lote && info.lote != '0000') {
        chave += 'l'
        valor += info.lote
      }
      if (info.condominio) {
        chave += 'c'
        valor += info.condominio
      }
      return { [chave]: valor }
    }
    const query = getIPTUQuery(info)
    return (await universal.getGenericRegisters({ company: 'public', module: 'data', dataId: this.getCurrentDataId({ register }), action, query, project })).data
  }
  calculeSumAreaIPTU = function({ register, fields }) {
    const field = js_functions.getJSONObject({ fields, name: 'lotes' })
    const value = js_functions.operationArrayProp(register.lotes, 'sum', 'areaIPTU')
    field.footers[1].text = js_functions.numberOut(value, 2)
  }
}

