"use strict"

export class dialog {
  #page
  #dialog = {}
  static empty = { show: false, title: '', text: '', buttonYes: '', buttonNo: '', action: function() { return false } }
  constructor({ page, data }) {
    Object.assign(this.#dialog, (data || dialog.empty))
    this.#page = page
  }
  open = () => this.#page.openDialog({ dialog: this.#dialog })
  close = () => this.#page.closeDialog()
}