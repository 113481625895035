<template>
  <div v-if="field.show == undefined || field.show" class="FieldMap" :style="`flex: ${field.grow ? field.grow : 1} 1 ${field.basis ? field.basis : 0}; min-width: ${field.minwidth}; max-width: ${field.maxwidth};`">
    <div id="divMain" :class="{ 'aux': field.aux?.show }" :style="`min-height: ${field.minheight};`">
      <div v-show="field.aux?.show" class="auxTitle left">{{field.aux?.mainTitle}}</div>
      <div id="divMap" ref="divMap" class="divMap left" :class="{ 'editable': mapEditable, 'aux': field.aux?.show }" :style="`min-height: ${field.minheight};`"></div>
    </div>
    <div v-show="field.aux?.show" id="divAux" :class="{ 'aux': field.aux?.show }" :style="`min-height: ${field.minheight};`">
      <div v-show="field.aux?.show" class="auxTitle right">{{field.aux?.auxTitle}}</div>
      <div id="divMapAux" ref="divMapAux" class="divMap right" :class="{ 'editable': mapEditable, 'aux': field.aux?.show }" :style="`min-height: ${field.minheight};`"></div>
      <v-btn @click="event(field.aux?.auxAction)" color="success">Concluir</v-btn>
    </div>
    <FieldTextarea :field="FieldTextarea()" :vmodel="vmodelTexarea" :logic="logic" :FormLines="FormLines"/>
    <div class="polygonArea">
      <FieldText :field="fieldArea()" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
    </div>
    <div class="polygonPerimetro">
      <FieldText :field="fieldPerimetro()" :vmodel="vmodel || register" :logic="logic" :FormLines="FormLines"/>
    </div>
    <div id="toolBarField">
    <v-tooltip right v-if="field.draw == undefined || field.draw">
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium :class="{ 'editable': mapEditable }" @click="clickDrawButton" v-bind="attrs" v-on="on">mdi-vector-square</v-icon>
      </template>
      <span v-if="!mapEditable">Desenhar Polígono</span>
      <span v-if="mapEditable">Salvar Polígono</span>
    </v-tooltip>
    <v-tooltip right v-if="field.markers">
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium :class="{ 'ativado': marker }" @click="clickMarker" v-bind="attrs" v-on="on">mdi-map-marker</v-icon>
      </template>
      <span>Adicionar Marcador</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium :class="{ 'ativado': scrollwheel }" @click="click('scrollwheel')" v-bind="attrs" v-on="on">mdi-mouse</v-icon>
      </template>
      <span>Zoom | Rolagem</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium :class="{ 'ativado': poi }" @click="click('poi')" v-bind="attrs" v-on="on">mdi-store-marker</v-icon>
      </template>
      <span>Estabelecimentos</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium :class="{ 'ativado': measure.active }" @click="clickMeasureButton" v-bind="attrs" v-on="on">mdi-ruler</v-icon>
      </template>
      <span>Medir Distância</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium @click="clickUserCurrentPosition" v-bind="attrs" v-on="on">mdi-crosshairs-gps</v-icon>
      </template>
      <span>Localização atual</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium @click="openFileSelector" v-bind="attrs" v-on="on">mdi-image-marker</v-icon>
        <input ref="fileInput" type="file" style="display: none" @change="handleFileSelect"/>
      </template>
      <span>Importar localização de imagem</span>
    </v-tooltip>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon medium @click="downloadPoligono" v-bind="attrs" v-on="on">mdi-download</v-icon>
      </template>
      <span>Download Polígono</span>
    </v-tooltip>
    </div>
  </div>
</template>

<script>
import * as files from '@/lib/files'
import * as downloads from '@/lib/downloads'
import exifr from 'exifr'
import FieldText from './FieldText'
import FieldTextarea from './FieldTextarea'
import { map_functions } from '@/lib/map_functions'
import { getGenericRegisters } from '@/lib/core/universal'
import * as iptus from '@/lib/core/public/iptus'
import * as js_functions from '@/lib/js_functions'
import { mapState } from 'vuex'
export default {
  name: "FieldMap",
  components: { FieldText, FieldTextarea },
  props: ['FormLines', 'logic', 'field', 'vmodel'],
  computed: { ...mapState(['register', 'user', 'mapEditable']) },
  data: () => ({
    map: null,
    geocoder: null,
    logicMaps: null,
    functions: null,
    mapTypeId: 'roadmap',
    drawWithMeasure: null,
    marker: false,
    clickListener: null,
    scrollwheel: false,
    poi: true,
    measure: { active: false, measureTool: null },
    style: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      }
    ],
    options: null,
    objects: [],
    editable: false,
    vmodelTexarea: { coords: '' }
  }),
  methods: {
    FieldTextarea() {
      let _field = { ...this.field }
      _field.type = 'textarea'
      _field.name = 'coords'
      _field.show = false
      return _field
    },
    fieldArea() { return {
      "name": `${this.field.name}Area`,
      "type": "text",
      "suffix": "m²",
      "solo": true,
      "prependInnerIcon": "mdi-texture-box",
      "disabled": true
    }},
    fieldPerimetro() { return {
      "name": `${this.field.name}Perimetro`,
      "type": "text",
      "suffix": "m",
      "solo": true,
      "prependInnerIcon": "mdi-crop-square",
      "disabled": true
    }},
    clickMarker() {
      this.marker = !this.marker
      const _this = this
      if (this.marker) { this.clickListener = window.google.maps.event.addListener(this.map, 'click', function(event) { _this.event(_this.field.addMarker, event) }) }
      else { window.google.maps.event.removeListener(this.clickListener) }
    },
    click(bottom) {
      this[bottom] = !this[bottom]
      this.options.scrollwheel = this.scrollwheel
      this.options.styles = !this.poi ? this.style : null
      const currentMapTypeId = this.map.getMapTypeId()
      this.options.mapTypeId = currentMapTypeId
      this.map.setOptions(this.options)
    },
    _parent() { return this.multiline == undefined ? this.$parent.$parent : this.$parent },
    event(functionName, infosMap) { if (functionName) { this.logic[functionName]({ infosMap, parent: this._parent(), FormLines: this.FormLines, name: this.field.name, value: this.vmodel[this.field.name], vmodel: this.vmodel, lineNumber: this.line }) } },
    async start() {
      this.logicMaps = await files.getJSNewObject({ path: `${this.user.company}/logicMaps.js`, newParams: { files, map_functions } })
      this.functions = new map_functions
      this.mapTypeId = this.logicMaps?.fieldMapStartConfiguration?.mapTypeId ? this.logicMaps.fieldMapStartConfiguration.mapTypeId : this.mapTypeId
      this.scrollwheel = this.logicMaps?.fieldMapStartConfiguration?.scrollwheel ? this.logicMaps.fieldMapStartConfiguration.scrollwheel : this.scrollwheel
      this.poi = this.logicMaps?.fieldMapStartConfiguration?.poi ? this.logicMaps.fieldMapStartConfiguration.poi : this.poi
      this.options = {
        mapTypeId: this.mapTypeId,
        scrollwheel: this.scrollwheel,
        scaleControl: true
      }
      if (!this.poi) { this.options.styles = this.style }
      this.functions.configMap(this, this.$refs.divMap, this.options)
      this.FormLines.Form.maps[this.field.name] = this.map
      if (!this.vmodel[this.field.name] || this.vmodel[this.field.name] == '') {
        if (typeof this.logicMaps.loadMap === 'function') this.logicMaps.loadMap(this)
        if( typeof this.logicMaps.loadFieldMap === 'function') this.logicMaps.loadFieldMap({ Map: this } )
      }
      else { this.insertMapObject() }
    },
    calculeAreaAndPerimetro() {
      let area = 0
      const isPolygon = (objeto) => typeof objeto.getPath == 'function'
      const hasOnlyOnePolygon = () => this.objects.filter(objeto => isPolygon(objeto)).length == 1
      this.objects.map(object => area += isPolygon(object) ? window.google.maps.geometry.spherical.computeArea(object.getPath()) : 0)
      const perimeterPolygon = this.objects.find(object => isPolygon(object))
      const perimeter = perimeterPolygon ? window.google.maps.geometry.spherical.computeLength(perimeterPolygon.getPath()) : 0
      this.$store.commit('changemapEditable', true)
      this.vmodel[`${this.field.name}Area`] = js_functions.numberOut(area, 2) || ''
      if (this.objects.length == 1 && hasOnlyOnePolygon()) { this.vmodel[`${this.field.name}Perimetro`] = js_functions.numberOut(perimeter, 2) }
      if (!hasOnlyOnePolygon()) { this.vmodel[`${this.field.name}Perimetro`] = '' }
      this.$store.commit('changemapEditable', false)
    },
    insertMapObject() {
      if (!this.vmodel[this.field.name]) { return }
      if (!this.vmodel[this.field.name][0]) { return }
      if (this.mapEditable) { return }
      this.objects.map(object => object.setMap(null))
      const objectsHole = this.vmodel[this.field.name].filter(_polygon => _polygon.info && _polygon.info.hole)
      const objectsNotHole = this.vmodel[this.field.name].filter(_polygon => !_polygon.info || !_polygon.info.hole)
      const getHoles = () => objectsHole.map(hole => hole.info.reverse ? hole[this.field.name].reverse() : hole[this.field.name])
      const getCoords = (polygon) => {
        if (!polygon[this.field.name]) { return { coords: polygon } }
        if (objectsHole.length == 0) { return { coords: polygon[this.field.name] } }
        let coordsWithHoles = getHoles()
        coordsWithHoles.unshift(polygon[this.field.name])
        return { coordsWithHoles }
      }
      this.objects = objectsNotHole.map(_polygon => {
        const _coords = getCoords(_polygon)
        const objectType = _coords.coords.length == 1 ? 'Marker' : 'Polygon'
        return this.functions.insertMapObject({
          Map: this,
          type: objectType,
          register: this.vmodel,
          ..._coords,
          polygon: _polygon,
          options: (builder) => {
            if (!builder) { return true }
            if (objectType == 'Marker') { return this.markerConfig(this.getMarkerType(_polygon), _polygon) }
            if (objectType == 'Polygon') {
              const defaultOptions = { fillOpacity: 0.0, strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2, zIndex: 10, clickable: this.field.draw == undefined || this.field.draw == true ? true : false }
              const options = Object.assign({}, defaultOptions, _polygon.options)
              return options
            }
          },
          info: (builder) => {
            if (!builder) { return true }
            let content = objectType == 'Marker' ? `<h3 class="infowindowTitle">Marcador</h3>` : `<h3 class="infowindowTitle">Polígono</h3>`
            if (objectType == 'Marker' && _polygon.info) {
              content = _polygon.info.titulo ? `<h3 class="infowindowTitle">${_polygon.info.titulo}</h3>` : ''
              content += _polygon.info.informacao ? `<div class="infoBlock"><h4>Informações</h4><ul>${_polygon.info.informacao}</ul></div>` : ''
              content += _polygon.info.foto ? `<img src="${_polygon.info.foto}" width="auto" height="200">` : ''
              return content
            }
            let info = _polygon.info ? Object.keys(_polygon.info).map(key => `<li><label>${key}:</label> ${_polygon.info[key]}</li>`).join('') : ''
            const buttonRemove = this.field.draw == undefined || this.field.draw == true && objectType == 'Polygon' ? '<button id="infoWindow_removerPoligono">Remover</button>' : ''
            const buttonMesuare = this.field.draw == undefined || this.field.draw == true && objectType == 'Polygon' ? '<button id="infoWindow_medidasPoligono">Medidas</button>' : ''
            const buttonEdit = this.field.draw == undefined || this.field.draw == true && objectType == 'Polygon' ? '<button id="infoWindow_editarPoligono">Editar</button>' : ''
            const buttonUseAdress = this.field.draw == undefined || this.field.draw == true && objectType == 'Polygon' ? '<button type="button" id="infoWindow_usarEndereco">Usar Endereço</button>' : ''
            content += info != '' ? `<div class="infoBlock"><h4>Informações</h4><ul>${info}</ul></div>` : ''
            content += `<div class="infoButtons">
              ${buttonRemove}
              ${buttonMesuare}
              ${buttonEdit}
              ${buttonUseAdress}
            </div>`
            return content
          },
          events: [
            {
              obj: '#infoWindow_removerPoligono',
              event: 'onclick',
              action: async (params) => {
                params.object.setMap(null)
                this.vmodel[`${this.field.name}Area`] = ''
                this.vmodel[`${this.field.name}Perimetro`] = ''
                const coords = _polygon.poligono ? _polygon.poligono : _polygon
                this.vmodel[this.field.name] = this.vmodel[this.field.name].filter(polygon => {
                  const _coords = polygon.poligono ? polygon.poligono : polygon
                  return _coords != coords
                })
                if (params.builder.polygon?.info?.Lote && this.FormLines?.register?.lotes) {
                const index = this.FormLines.register.lotes.findIndex(element => element.link_lote == params.builder.polygon.info.Lote ? true : false)
                index != -1 && this.FormLines.register.lotes.splice(index, 1)
                this.lotes.reCalculeFieldsByChangeLotes(this.FormLines)
                }
                params.infoWindow.close()
              }
            },
            {
              obj: '#infoWindow_medidasPoligono',
              event: 'onclick',
              action: async (params) => {
                const coords = _polygon.poligono ? _polygon.poligono : _polygon
                this.measure.active = true
                this.measure.measureTool = this.functions.startToolMeasure({ Map: this.map, mapObjects: this.objects })
                await this.measure.measureTool.start(coords)
                params.infoWindow.close()
              }
            },
            {
              obj: '#infoWindow_editarPoligono',
              event: 'onclick',
              action: async (params) => {
                params.object.setMap(null)
                const coords = _polygon.poligono ? _polygon.poligono : _polygon
                this.$store.commit('changemapEditable', true)
                this.measure.active = true
                this.drawWithMeasure = this.functions.startDrawWithMeasure({ Map: this.map, mapObjects: this.objects })
                this.vmodel[this.field.name] = this.vmodel[this.field.name].filter(polygon => {
                  const _coords = polygon.poligono ? polygon.poligono : polygon
                  return _coords != coords
                })
                await this.drawWithMeasure.start(coords)
                params.infoWindow.close()
              }
            },
            {
              obj: '#infoWindow_usarEndereco',
              event: 'onclick',
              action: async (params) => {
                params.builder.Map.$store.commit("showOverlays", 2)
                const sql = `${params.builder.register.link_quadra.split(' | ')[1].split(' (')[0].replace('.', '')}${_polygon.info.Lote}`
                const iptuCurrentDataId = (new iptus.iptus()).getCurrentDataId({ register: params.builder.register })
                const iptu = (await window.jsf.getFunc({ func: () => getGenericRegisters({ backend: params.builder.Map.FormLines.Form.backend, company: 'public', dataId: iptuCurrentDataId, query: { sql }, project: { "NOME DE LOGRADOURO DO IMOVEL": 1, "NUMERO DO IMOVEL": 1 }, action: 'read/one' }), returnData: true })).data
                if (iptu) { this.vmodel.endereco = `${iptu["NOME DE LOGRADOURO DO IMOVEL"]} ${iptu["NUMERO DO IMOVEL"]}` }
                params.infoWindow.close()
                params.builder.Map.$store.commit("hideOverlays", 2)
              }
            }
          ]
        })
      })
      this.functions.fitBoundsToVisibleObjects(this.map, this.objects)
      this.calculeAreaAndPerimetro()
    },
    getMarkerType(polygon) {
      if (polygon.info?.active) { return 'customActive' }
      if (polygon.info?.label) { return 'defaultLabel' }
      return 'default'
    },
    markerConfig(markerType, polygon) {
      return {
        default: { clickable: true },
        defaultLabel: { label: polygon.info?.label, clickable: true },
        customActive: { label: polygon.info?.label, icon: { path: window.google.maps.SymbolPath.CIRCLE, scale: 10, fillColor: '#00FF00', fillOpacity: 1, strokeWeight: 0 }, clickable: true }
      }[markerType]
    },
    clickMeasureButton() {
      this.measure.active = !this.measure.active
      if (this.mapEditable) {
        if (this.measure.active) { this.drawWithMeasure?.setOption('showSegmentLength', true) }
        else { this.drawWithMeasure?.setOption('showSegmentLength', false) }
        return
      }
      if (!this.measure.measureTool) { this.measure.measureTool = this.functions.startToolMeasure({ Map: this.map, mapObjects: this.objects }) }
      !this.measure.active ? this.measure.measureTool?.end() : this.measure.measureTool?.start()
    },
    downloadPoligono() {
      const poligonName = this.vmodel[this.field.poligonNameField] ? this.vmodel[this.field.poligonNameField] : 'poligono'
      const data = downloads.kml({ documentName: poligonName, placemarkName: poligonName, coordinates: this.vmodel[this.field.name] })
      downloads.downloadFile({ data, type: 'text/xml', name: `${poligonName}.kml` })
    },
    clickUserCurrentPosition() { this.functions.getUserCurrentPosition({ Map: this, zoom: 20, insertMarker: true, buttonInsertTerreno: false }) },
    openFileSelector() { this.$refs.fileInput.click() },
    async handleFileSelect(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile.type.startsWith("image")) {
        this.metadados = await exifr.parse(selectedFile)
        this.functions.setMapCenter({ Map: this, lat: this.metadados.latitude, lng: this.metadados.longitude, zoom: 20, insertMarker: true, buttonInsertTerreno: false, infowindowTitle: 'Localização imagem' })
      }
      else { this.$store.commit("showAlert", { type: 'warning', text: 'O arquivo tem que ser do tipo imagem!', dismissible: true, time: 5000 }) }
    },
    clickDrawButton() {
      this.measure.measureTool?.end()
      if (this.mapEditable && this.functions.polygonInfos?.comprimentoTotal != 0 && this.functions.polygonInfos?.areaTotal == 0) {
        return this.$store.commit("showAlert", { type: 'warning', text: 'Fechar o polígono para salvar!', dismissible: true, time: 3000 })
      }
      this.$store.commit('changemapEditable', !this.mapEditable)
      if (!this.mapEditable && this.functions.polygonInfos.coords.length <= 1 && this.functions.polygonInfos?.areaTotal == 0) {
        if (this.drawWithMeasure) { this.drawWithMeasure.end() }
        this.measure.active = false
        return
      }
      if (this.mapEditable) {
        this.measure.active = true
        this.objects.map(object => object.setMap(null))
        this.vmodel[this.field.name] = []
        this.vmodel[`${this.field.name}Area`] = ''
        this.vmodel[`${this.field.name}Perimetro`] = ''
        this.drawWithMeasure = this.functions.startDrawWithMeasure({ Map: this.map, mapObjects: this.objects })
        this.drawWithMeasure.start()
      }
      else {
        this.measure.active = false
        this.drawWithMeasure.end()
        this.vmodel[this.field.name].push(this.functions.polygonInfos.coords)
        const isPolygon = (objeto) => typeof objeto.getPath == 'function'
        const hasOnlyOnePolygon = () => this.objects.filter(objeto => isPolygon(objeto)).length == 1
        if (hasOnlyOnePolygon()) {
          this.vmodel[`${this.field.name}Area`] = js_functions.numberOut(this.functions.polygonInfos.areaTotal, 0)
          this.vmodel[`${this.field.name}Perimetro`] = js_functions.numberOut(this.functions.polygonInfos.comprimentoTotal, 0)
        }
        this.insertMapObject()
      }
    }
  },
  async mounted() {
    this.vmodelTexarea.coords = Array.isArray(this.vmodel[this.field.name]) ? this.vmodel[this.field.name].join() : ''
    this.$store.commit('changemapEditable', false)
    await this.start()
    this.$watch(`vmodel.${this.field.name}`, () => {
      this.vmodelTexarea.coords = Array.isArray(this.vmodel[this.field.name]) ? this.vmodel[this.field.name].join() : ''
      this.insertMapObject()
      if(!this.vmodelTexarea.coords) { this.objects.map(object => object.setMap(null)) }
    })
    this.FormLines.components.FieldMap.push(this)
    this.lotes = await this.FormLines.Form.files.getJS(`_universal/terrenos/lotes.js`)
  }
  // destroyed() { files.unloadScript(`${this.user.company}/logicMaps.js`) }
}
</script>

<style scoped>
  div.FieldMap {
    min-height: 300px;
    padding: 0px 5px;
    position: relative;
  }
  div.FieldMap div.divMap {
    height: 300px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    border-radius: 4px;
  }
  div.FieldMap div.divMap:hover { border-color: rgba(0, 0, 0, 0.87); }
  div.FieldMap div.divMap.editable { border: 2px solid var(--over-color); }
  div#toolBarField {
    z-index: 8;
    position: absolute;
    background-color: white;
    width: 45px;
    height: auto;
    top: 100px;
    left: 16px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    padding-top: 6px;
  }
  div#toolBarField > .v-icon {
    z-index: 8;
    padding: 5px 9px 8px 10px;
    border: 1px solid white;
  }
  div#toolBarField > .v-icon:hover {
    color: rgba(0, 0, 0, 0.80);
    cursor: pointer;
  }
  div#toolBarField > .v-icon.v-icon::after { background: none; }
  div#toolBarField > .v-icon.editable { color: #00CC00; }
  div#toolBarField > .v-icon.editable:hover { color: #00BB00; }
  div#toolBarField > .v-icon.ativado { color: #CC0000; }
  div#toolBarField > .v-icon.ativado:hover { color: #BB0000; }
  div.FieldMap div.polygonArea,
  div.FieldMap div.polygonPerimetro {
    z-index: 8;
    position: absolute;
    width: 180px;
    bottom: 20px;
  }
  div.FieldMap div.polygonArea { left: 16px; }
  div.FieldMap div.polygonPerimetro { left: 200px; }
  /* div#formLines div.line div.FieldMap div.polygonArea .v-input { background: white; } (Parece que não é necessário) */

  /* AUX */
  div.FieldMap div.divMap.aux.left { border-radius: 0px 0px 0px 4px; }
  div.FieldMap div.divMap.aux.right { border-radius: 0px 0px 4px 0px; }
  div#divMain.aux,
  div#divAux.aux
  {
    width: 50%;
    float: left;
    position: relative;
    overflow: hidden;
  }
  div#divMap,
  div#divMapAux
  {
    width: 100%;
    position: relative;
    top: 30;
    left: 0;
  }
  div#divMapAux { right: 0; }
  div.auxTitle { 
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    padding: 2px 5px;
    text-align: center;
    font-weight: bold;
  }
  div.auxTitle.left {
    border-radius: 4px 0px 0px 0px;
    background-color: #ff6160;
  }
  div.auxTitle.right {
    border-radius: 0px 4px 0px 0px;
    background-color: #5cb55e;
  }
  div#divAux .v-btn {
    width: 70%;
    position: absolute;
    bottom: 10px;
    right: 15%;
  }
</style>