"use strict"

import * as universal from '@/lib/core/universal'

export class linkDataVersionsManager {
  #company
  #dataVersionName
  #newVersions
  #linksDataVersions
  constructor({ company, dataVersionName }) {
    this.#company = company
    this.#dataVersionName = dataVersionName
    const linkDataVersionsManager = this
    this.#linksDataVersions = class linksDataVersions {
      #linksDataVersions
      constructor({ register }) {
        if (register) { register.linksDataVersions = register?.linksDataVersions ? register?.linksDataVersions : [] }
        this.#linksDataVersions = register?.linksDataVersions
      }
      getLinkDataVersion = () => this.#linksDataVersions?.find(linkVersion => linkVersion.dataVersionName == linkDataVersionsManager.#dataVersionName)
      push = (value) => this.#linksDataVersions?.push(value)
    }
    class dataVersions extends Array {
      constructor() {
        class dataVersion {
          #dataVersion
          constructor({ data }) {
            this.#dataVersion = {}
            !data || Object.assign(this.#dataVersion, data)
            const dataVersion = this
            class versions extends Array {
              constructor({ data }) {
                class version {
                  #version
                  #info
                  constructor({ data }) {
                    this.#version = {}
                    !data || Object.assign(this.#version, data)
                    this.#info = !this.#version?.dataId
                      ? false
                      : {
                          dataVersionName: dataVersion?.name || '',
                          dataVersionLabel: dataVersion?.label || '',
                          versionTag: this.#version?.tag || '',
                          versionLabel: this.#version?.label || '',
                          versionDataId: this.#version?.dataId || ''
                        }
                  }
                  get dataId() { return this.#version?.dataId }
                  get tag() { return this.#version?.tag }
                  get label() { return this.#version?.label }
                  #infoAlreadyRegister = ({ linksDataVersions }) => linksDataVersions?.getLinkDataVersion()
                  addInfoToRegister = ({ linksDataVersions }) => (!this.#info || this.#infoAlreadyRegister({ linksDataVersions })) || linksDataVersions.push(this.#info)
                  updateInfoIntoRegister = ({ register }) => {
                    if (!this.#info) { return }
                    const index = register.linksDataVersions?.findIndex(_version => _version.dataVersionName == dataVersion.name)
                    Object.assign(register.linksDataVersions[index], this.#info)
                  }
                }
                super(...(data.versions?.map(versionData => new version({ data: versionData })) || ''))
              }
              getLast = () => this[this.length - 1]
              getByTag = (tag) => this.find(_version => _version?.tag == tag)
              getByDataId = (dataId) => this.find(_version => _version?.dataId == dataId)
              getVersion = ({ position, tag, dataId }) => {
                return {
                  last: () => this.getLast(),
                  tag: ({ tag }) => this.getByTag(tag),
                  dataId: ({ dataId }) => this.getByDataId(dataId)
                }[position]({ tag, dataId })
              }
            }
            this.#dataVersion.versions = new versions({ data: this.#dataVersion || [] })
          }
          get name() { return this.#dataVersion.name }
          get label() { return this.#dataVersion.label }
          get versions() { return this.#dataVersion.versions }
        }
        super(...(window[`${linkDataVersionsManager.#company}_versions`]?.map(data => new dataVersion({ data })) || ''))
      }
      get versions() { return this?.find(_dataVersion => _dataVersion?.name == linkDataVersionsManager.#dataVersionName)?.versions }
    }
    this.#newVersions = (new dataVersions())?.versions
  }
  #getVersionParams = ({ register }) => register?._id ? { position: 'tag', tag: 'default' } : { position: 'last' } //adicionar verificação se já tem algum link vinculado?
  getCurrentDataId = ({ register }) =>
    (new this.#linksDataVersions({ register }))
      ?.getLinkDataVersion()
      ?.versionDataId
    || this.#newVersions
      ?.getVersion(this.#getVersionParams({ register }))
      ?.dataId
  getLastDataId = () =>
    this.#newVersions
      ?.getVersion({ position: 'last' })?.dataId
  getVersion = ({ position, tag, dataId }) =>
    this.#newVersions
      ?.getVersion({ position, tag, dataId })
  setLinkDataVersion = ({ register, versionDataId }) =>
    this.#newVersions
      ?.getVersion({ position: 'dataId', dataId: versionDataId })
      ?.addInfoToRegister({ linksDataVersions: (new this.#linksDataVersions({ register })) })
  updateLinkVersion = ({ register }) =>
    this.#newVersions
      ?.getVersion({ position: 'last' })
      ?.updateInfoIntoRegister({ register })
  canUpdateDataId = async ({ query, project }) =>
    await universal
      ?.getGenericRegisters({
        company: this.#company,
        dataId: this.#newVersions?.getVersion({ position: 'last' }).dataId,
        query,
        project,
        action: 'read/one'
      })
}

export class fieldsToUpdate extends Array {
  constructor({ fields }) {
    super(...(fields || []))
  }
  checkAll = (prop) => this.reduce((result, field) => result = !result ? result : field[prop], true)
  updateAllDataId = async () => await Promise.all(this.map(field => field.updateDataId()))
  cannotUpdateAllText = () =>
    this
      ?.map(field => field.cannotUpdateText())
      ?.filter(text => text)
      ?.join("\n\n")
  sucessUpdatedAllText = () =>
    this
      ?.map(field => field.sucessUpdatedText())
      ?.filter(text => text)
      ?.join("\n\n")
}

export class updateInfo extends Array{
  constructor({ data }) {
    super(...(data || []))
  }
  get notEmpty() {return this.length > 0 }
  // getPropList = (prop) => this.map(info => this.#register?.lotes?.[info.lineIndex]?.link_lote)?.joinLastDifferent(', ', ' e ')
  getPropList = (prop) => this.map(info => info[prop])?.joinLastDifferent(', ', ' e ')
}